import React from "react"
import Layout from "../components/Base/Layout"
import PressArticle from "../components/Press/pressArticle"

export default function({ pageContext }) {
  return (
    <Layout>
      <PressArticle slug={pageContext.slug} />
    </Layout>
  )
}
