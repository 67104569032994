import React from "react"

export default function ({ pressItem }) {
  const getPublishedDate = (date) => {
    const months = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December');
    const createDate = new Date(date);

    return months[createDate.getMonth()] + " " + createDate.getFullYear();
  }

  return (
    <>
      <div className="text-decoration-none text-body h4 mb-2 font-weight-bold">
        {pressItem.title}
      </div>
      <div className="text-muted font-weight-light mt-2">
        Published : {getPublishedDate(pressItem.created_at)}
      </div>
      {/* <i className="fa fa-eye text-muted" style={{ paddingRight: "0.5em" }}></i>
      <span className="text-muted">{pressItem.views}</span> */}
      <div
        className="mt-3"
        dangerouslySetInnerHTML={{ __html: pressItem.body }}
      ></div>
    </>
  )
}
